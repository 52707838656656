import * as DialogPrimitive from '@radix-ui/react-dialog';
import * as React from 'react';
import Icon from '@/components/ui/Icon';
import { cn } from '@/utils';
const DIALOG_SIZES = {
  xs: 'sm:max-w-md',
  sm: 'sm:max-w-lg',
  md: 'sm:max-w-xl',
  lg: 'sm:max-w-2xl',
  xl: 'sm:max-w-3xl',
  '2xl': 'sm:max-w-4xl',
  full: 'sm:max-w-[95vw]'
};
const Dialog = DialogPrimitive.Root;
const DialogTrigger = DialogPrimitive.Trigger;
const DialogPortal = DialogPrimitive.Portal;
const DialogClose = DialogPrimitive.Close;
const DialogOverlay = ({
  ref,
  className,
  ...props
}) => <DialogPrimitive.Overlay ref={ref} className={cn('absolute inset-0 z-50 dialog-overlay backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0', className)} {...props} data-sentry-element="unknown" data-sentry-component="DialogOverlay" data-sentry-source-file="Dialog.jsx" />;
DialogOverlay.displayName = DialogPrimitive.Overlay.displayName;
const DialogContent = ({
  ref,
  className,
  children,
  onClick,
  size = 'xl',
  disableOutsideClick = false,
  ...props
}) => <DialogPortal data-sentry-element="DialogPortal" data-sentry-component="DialogContent" data-sentry-source-file="Dialog.jsx">
  <DialogOverlay onClick={disableOutsideClick ? undefined : onClick} data-sentry-element="DialogOverlay" data-sentry-source-file="Dialog.jsx" />
  <DialogPrimitive.Content ref={ref} aria-describedby={undefined} onPointerDownOutside={disableOutsideClick ? e => e.preventDefault() : undefined} onInteractOutside={disableOutsideClick ? e => e.preventDefault() : undefined} className={cn('dialog fixed left-[50%] top-[50%] z-50 grid w-full translate-x-[-50%] translate-y-[-50%] px-5 pb-5 shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] md:w-full max-h-[85svh] overflow-y-auto overflow-x-hidden scroll-container focus:outline-none focus-visible:outline-none', DIALOG_SIZES[size], className)} {...props} data-sentry-element="unknown" data-sentry-source-file="Dialog.jsx">
    {children}
  </DialogPrimitive.Content>
</DialogPortal>;
DialogContent.displayName = DialogPrimitive.Content.displayName;
const DialogHeader = ({
  className,
  onClick,
  ...props
}) => <div className={cn('flex flex-col sticky top-0 py-5 z-20 bg-primary', className)} {...props} data-sentry-component="DialogHeader" data-sentry-source-file="Dialog.jsx">
    {props.children}
    <DialogPrimitive.Close className="absolute w-10 h-10 right-0 top-3 opacity-70 transition-opacity hover:opacity-100 focus:outline-none disabled:pointer-events-none data-[state=open]:bg-accent data-[state=open]:text-muted-foreground" onClick={onClick} data-sentry-element="unknown" data-sentry-source-file="Dialog.jsx">
      <Icon name="X" className="w-5 h-5" data-sentry-element="Icon" data-sentry-source-file="Dialog.jsx" />
      <span className="sr-only">Close</span>
    </DialogPrimitive.Close>
  </div>;
DialogHeader.displayName = 'DialogHeader';
const DialogFooter = ({
  className,
  ...props
}) => <div className={cn('flex flex-col sm:flex-row sm:justify-end sm:space-x-2', className)} {...props} data-sentry-component="DialogFooter" data-sentry-source-file="Dialog.jsx" />;
DialogFooter.displayName = 'DialogFooter';
const DialogTitle = ({
  ref,
  className,
  ...props
}) => <DialogPrimitive.Title ref={ref} className={cn('text-lg font-semibold leading-none tracking-tight m-0 max-w-[90%]', className)} {...props} data-sentry-element="unknown" data-sentry-component="DialogTitle" data-sentry-source-file="Dialog.jsx" />;
DialogTitle.displayName = DialogPrimitive.Title.displayName;
const DialogDescription = ({
  ref,
  className,
  ...props
}) => <DialogPrimitive.Description ref={ref} className={cn('text-sm text-muted-foreground', className)} {...props} data-sentry-element="unknown" data-sentry-component="DialogDescription" data-sentry-source-file="Dialog.jsx" />;
DialogDescription.displayName = DialogPrimitive.Description.displayName;
export { Dialog, DialogClose, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogOverlay, DialogPortal, DialogTitle, DialogTrigger };