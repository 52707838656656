'use client';

import Badge from './Badge';
import { STATUSES } from '@/data';
import { useTranslations } from '@/hooks';
function BadgeStatus({
  size,
  status,
  showIcon = true,
  ...props
}) {
  const t = useTranslations();
  const getStatusIcon = () => {
    switch (status) {
      case STATUSES.CANCELLED:
        return 'X';
      case STATUSES.CLOSED:
        return 'Lock';
      case STATUSES.COMPLETED:
        return 'Check';
      case STATUSES.DISPUTE:
        return 'TriangleAlert';
      case STATUSES.IN_PROGRESS:
        return 'Play';
      case STATUSES.PAID:
        return 'CreditCard';
      case STATUSES.PENDING:
        return 'Hourglass';
      case STATUSES.REFUNDED:
        return 'RefreshCcw';
      default:
        return status;
    }
  };
  const getStatusVariant = () => {
    switch (status) {
      case STATUSES.CANCELLED:
        return 'red-light';
      case STATUSES.CLOSED:
        return 'red-light';
      case STATUSES.COMPLETED:
        return 'green-light';
      case STATUSES.DISPUTE:
        return 'yellow-light';
      case STATUSES.IN_PROGRESS:
        return 'blue-light';
      case STATUSES.PAID:
        return 'green-light';
      case STATUSES.PENDING:
        return 'orange-light';
      case STATUSES.REFUNDED:
        return 'primary-light';
      default:
        return status;
    }
  };
  return <Badge size={size} variant={getStatusVariant()} label={t(`common.status.${status}`)} icon={showIcon ? getStatusIcon() : null} {...props} data-sentry-element="Badge" data-sentry-component="BadgeStatus" data-sentry-source-file="BadgeStatus.jsx" />;
}
export default BadgeStatus;