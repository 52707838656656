'use client'

import { useSearchParams } from 'next/navigation'
import { useEffect } from 'react'
import { hotjar } from 'react-hotjar'
import { matchfyApi } from '@/api'
import { useConsent } from '@/context/ConsentProvider'
import { PLANS } from '@/data'
import { useAnalytics, useSession, useTranslations } from '@/hooks'
import { usePathname, useRouter } from '@/i18n/routing'
import { EVENTS, trackEvent, toast, mxp } from '@/lib'
import {
  useAffiliateStore,
  useAppStore,
  useDevStore,
  useMetadataStore,
  useDiscountStore
} from '@/store'

export const Tracking = () => {
  const { consent } = useConsent()
  const { data: session, update } = useSession()
  const { user } = session || {}

  const pathname = usePathname()
  const router = useRouter()
  const t = useTranslations()
  const searchParams = useSearchParams()
  const { gtag, gtagPageView } = useAnalytics()

  const {
    completeRegistration,
    isNewUser,
    setCompleteRegistration,
    setIsNewUser
  } = useAppStore()
  const { affiliate, setAffiliate, clearAffiliate } = useAffiliateStore()
  const { setMetadata } = useMetadataStore()
  const { setIsDev, setEnabledFlags, setDevFlags } = useDevStore()
  const { discount, applyDiscountCode } = useDiscountStore()

  const clearParams = () => {
    router.replace(pathname, undefined, { shallow: true })
    clearAffiliate()
  }

  const handleAffiliate = async () => {
    if (!user) return
    const code = searchParams.get('code')
    const discountFromUrl = searchParams.get('discount')
    const plan = searchParams.get('plan')

    try {
      // Fetch affiliate code
      if (code && code.length > 0 && affiliate?.code !== code) {
        const affiliateData = await matchfyApi.getAffiliateOrReferral(code)
        setAffiliate(affiliateData)
        toast('success', t('affiliate.applied'))
      }

      if (discountFromUrl && discountFromUrl.length > 0 && discount !== discountFromUrl && [PLANS.AGENCY, PLANS.ARTIST].includes(plan)) {
        const p = await matchfyApi.getPlans("it")
        await applyDiscountCode(plan, p.find(p => p.slug === plan)?.price, discountFromUrl)
        toast('success', t('discount.applied'))
      }

      // Referred by another user
      if (user && affiliate?.userReferral) {
        await matchfyApi.useAffiliateCode(code)
        update()
        clearParams()
      }
    } catch (error) {
      clearParams()
      clearAffiliate()

      if (error?.response?.data?.message) {
        toast('error', t(error?.response?.data?.message) || error?.message)
      } else {
        toast('error', error?.message)
      }
    }
  }

  const handleUtmParams = () => {
    const utmSource = searchParams.get('utm_source')
    const utmCampaign = searchParams.get('utm_campaign')
    const utmContent = searchParams.get('utm_content')
    const gclid = searchParams.get('gclid')

    if (utmSource && utmCampaign && utmContent && gclid) {
      setMetadata({
        utmSource,
        utmCampaign,
        utmContent,
        gclid,
      })
    }
  }

  const trackNewUser = async (user) => {
    if (!user) return

    try {
      await trackEvent(EVENTS.NEW_USER, { user }, user.spotifyId)
      setCompleteRegistration(true)
      setIsNewUser(false)
    } catch (error) {
      console.log(error)
    }
  }

  useEffect(() => {
    if (window?.Rewardful) {
      window.Rewardful.ready(function () {
        console.log('rewardful referral', window.Rewardful.referral)
      })
    }
  }, [])

  useEffect(() => {
    if (session && session.newUser !== isNewUser && isNewUser === null) {
      setIsNewUser(session.newUser)
    }

    if (session?.dev) {
      setIsDev(session?.dev)
    }

    if (session?.devFlags) {
      setDevFlags(session?.devFlags)
      setEnabledFlags(session?.devFlags)
    }
  }, [session])

  useEffect(() => {
    handleAffiliate()
    handleUtmParams()
  }, [searchParams, user])

  useEffect(() => {
    if (isNewUser && !completeRegistration) {
      trackNewUser(session?.user)
    }
  }, [isNewUser])

  useEffect(() => {
    if (consent) {
      mxp.init()

      if (process.env.NEXT_PUBLIC_HOTJAR_ID) {
        hotjar.initialize({ id: process.env.NEXT_PUBLIC_HOTJAR_ID })
      }

      if (gtag) {
        gtagPageView(pathname + searchParams.toString())
      }
    }
  }, [consent])

  useEffect(() => {
    if (consent && user) {
      mxp.identify(user.spotifyId)
      mxp.people.set({
        $email: user?.contacts?.email,
        $phone: user?.contacts?.phone,
        plan: user?.subscription?.plan
      })
    }
  }, [user])

  useEffect(() => {
    if (consent) {
      mxp.track('Page View', { pathname })
    }
  }, [pathname])
}

export default Tracking
