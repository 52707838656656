'use client';

import { useQuery } from '@tanstack/react-query';
import React, { createContext, useContext } from 'react';
import { matchfyApi } from '@/api';
import Maintenance from '@/components/Maintenance';
import Spinner from '@/components/ui/Spinner';
import { ROUTES_DATA } from '@/data';
import { useSession } from '@/hooks';
import { usePathname } from '@/i18n/routing';
const MaintenanceContext = createContext();
export const useMaintenance = () => useContext(MaintenanceContext);
export const MaintenanceProvider = ({
  children
}) => {
  const pathname = usePathname();
  const {
    data: session,
    status
  } = useSession();
  const {
    data,
    isLoading
  } = useQuery({
    queryKey: ['maintenance'],
    queryFn: () => matchfyApi.getMaintenance()
  });
  const getChildren = () => {
    if (isLoading || status === 'loading') {
      return <div className="flex items-center justify-center w-full h-screen">
          <Spinner />
        </div>;
    }
    if (!session?.whitelist && data?.maintenance && pathname !== ROUTES_DATA.LOGIN.path) {
      return <Maintenance />;
    }
    return children;
  };
  return <MaintenanceContext.Provider value={{
    maintenance: data?.maintenance ?? false
  }} data-sentry-element="unknown" data-sentry-component="MaintenanceProvider" data-sentry-source-file="MaintenanceProvider.jsx">
      {getChildren()}
    </MaintenanceContext.Provider>;
};
export default MaintenanceProvider;