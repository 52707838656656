'use client'

import Script from 'next/script'
import { useState, useEffect } from 'react'
import {
  GA4_ID,
  INTERCOM_APP_ID,
  META_PIXEL_ID,
  TAG_MANAGER_ID,
  TIKTOK_PIXEL_ID,
  REWARDFUL_ID
} from '@/data'

const ThirdPartyScripts = () => {
  const [loaded, setLoaded] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined' && window?._iub) {
      setLoaded(true)
    }
  }, [])

  return (
    <>
      {loaded && (
        <>
          {/* Google Analytics */}
          <Script
            id="google-analytics"
            strategy="afterInteractive"
            data-iub-purpose="2"
            className="_iub_cs_activate-inline"
          >
            {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', '${GA4_ID}');
        `}
          </Script>

          {/* Google Tag Manager */}
          <Script
            id="google-tag-manager"
            strategy="afterInteractive"
            data-iub-purpose="2"
            className="_iub_cs_activate-inline"
          >
            {`
          (function (w, d, s, l, i) {
            w[l] = w[l] || [];
            w[l].push({ 'gtm.start': new Date().getTime(), event: 'gtm.js' });
            var f = d.getElementsByTagName(s)[0],
              j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : '';
            j.async = true;
            j.src = 'https://www.googletagmanager.com/gtm.js?id=' + i + dl;
            f.parentNode.insertBefore(j, f);
          })(window, document, 'script', 'dataLayer', '${TAG_MANAGER_ID}');
        `}
          </Script>

          {/* Intercom */}
          <Script
            id="intercom-lib"
            strategy="afterInteractive"
            data-iub-purpose="2"
            className="_iub_cs_activate-inline"
          >
            {`(function(){var w=window;var ic=w.Intercom;if(typeof
        ic==="function"){ic('reattach_activator');ic('update',w.intercomSettings);}else{var d=document;var
        i=function(){i.c(arguments);};i.q=[];i.c=function(args){i.q.push(args);};w.Intercom=i;var l=function(){var
        s=d.createElement('script');s.type='text/javascript';s.async=true;s.src='https://widget.intercom.io/widget/${INTERCOM_APP_ID}';var
        x=d.getElementsByTagName('script')[0];x.parentNode.insertBefore(s,x);};if(w.attachEvent){w.attachEvent('onload',l);}else{w.addEventListener('load',l,false);}}})();`}
          </Script>

          <Script
            id="intercom"
            strategy="afterInteractive"
            data-iub-purpose="2"
            className="_iub_cs_activate-inline"
          >
            {`(function () {
          if(window.intercomSettings){
            window.Intercom('boot', {
              ...window.intercomSettings
            })
          }
        })();`}
          </Script>

          {/* Mailchimp */}
          <Script
            id="mailchimp"
            strategy="afterInteractive"
            data-iub-purpose="5"
            className="_iub_cs_activate-inline"
          >
            {`!function (c, h, i, m, p) {
          m = c.createElement(h),
          p = c.getElementsByTagName(h)[0],
          m.async = 1,
          m.src = i,
          p.parentNode.insertBefore(m, p)
        }(document, "script", "https://chimpstatic.com/mcjs-connected/js/users/0fb55229378ab16d5a9c3aec3/e70a96b377a939e5c69da97a8.js");`}
          </Script>

          {/* Meta Pixel Code */}
          <Script
            id="meta-pixel"
            strategy="afterInteractive"
            data-iub-purpose="3"
            className="_iub_cs_activate-inline"
          >
            {`!function (f, b, e, v, n, t, s) {
          if (f.fbq) return;
          n = f.fbq = function () {
            n.callMethod
              ? n.callMethod.apply(n, arguments)
              : n.queue.push(arguments);
          };
          if (!f._fbq) f._fbq = n;
          n.push = n;
          n.loaded = !0;
          n.version = "2.0";
          n.queue = [];
          t = b.createElement(e);
          t.async = !0;
          t.src = v;
          s = b.getElementsByTagName(e)[0];
          s.parentNode.insertBefore(t, s);
        }(window, document, "script", "https://connect.facebook.net/en_US/fbevents.js");
        fbq("init", ${META_PIXEL_ID});
        fbq("track", "PageView");`}
          </Script>
          <noscript>
            <img
              height="1" width="1"
              style={{ display: 'none' }}
              alt="Facebook Pixel"
              src={`https://www.facebook.com/tr?id=${META_PIXEL_ID}&ev=PageView&noscript=1`}
            />
          </noscript>

          {/* TikTok Pixel */}
          <Script
            id="tiktok-pixel"
            strategy="afterInteractive"
            data-iub-purpose="3"
            className="_iub_cs_activate-inline"
          >
            {`
          if (!window.ttqInitialized) {
            !function (w, d, t) {
              w.TiktokAnalyticsObject = t;
              var ttq = w[t] = w[t] || [];
              ttq.methods = ["page", "track", "identify", "instances", "debug", "on", "off", "once", "ready", "alias", "group", "enableCookie", "disableCookie"];
              ttq.setAndDefer = function (t, e) { t[e] = function () { t.push([e].concat(Array.prototype.slice.call(arguments, 0))) } };
              for (var i = 0; i < ttq.methods.length; i++)ttq.setAndDefer(ttq, ttq.methods[i]);
              ttq.instance = function (t) {
                for (var e = ttq._i[t] || [], n = 0; n < ttq.methods.length; n++
                )ttq.setAndDefer(e, ttq.methods[n]);
                return e
              },
              ttq.load = function (e, n) {
                var i = "https://analytics.tiktok.com/i18n/pixel/events.js";
                ttq._i = ttq._i || {}, ttq._i[e] = [], ttq._i[e]._u = i, ttq._t = ttq._t || {}, ttq._t[e] = +new Date, ttq._o = ttq._o || {}, ttq._o[e] = n || {};
                n = document.createElement("script");
                n.type = "text/javascript", n.async = !0, n.src = i + "?sdkid=" + e + "&lib=" + t;
                e = document.getElementsByTagName("script")[0];
                e.parentNode.insertBefore(n, e)
              };
              ttq.load('${TIKTOK_PIXEL_ID}');
              ttq.page();
            }(window, document, 'ttq');
            window.ttqInitialized = true;
          }
        `}
          </Script>

          {/* Trustpilot */}
          <Script
            id="trustpilot"
            strategy="afterInteractive"
            data-iub-purpose="3"
            src="https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js"
            className="_iub_cs_activate-inline"
          />

          {/* Rewardful */}
          {REWARDFUL_ID && (
            <>
              <Script src="https://r.wdfl.co/rw.js" data-rewardful={REWARDFUL_ID}></Script>
              <Script id="rewardful-queue" strategy="beforeInteractive">
                {`(function(w,r){w._rwq=r;w[r]=w[r]||function(){(w[r].q=w[r].q||[]).push(arguments)}})(window,'rewardful');`}
              </Script>
            </>
          )}
        </>
      )}
    </>
  )
}

export default ThirdPartyScripts
