'use client';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { SessionProvider } from 'next-auth/react';
import React from 'react';
import EmailVerify from '@/components/EmailVerify';
import { Tracking, WebVitals } from '@/components/Scripts';
import Tour from '@/components/Tour';
import { SidebarProvider } from '@/components/ui/Sidebar/Sidebar';
import { TooltipProvider } from '@/components/ui/Tooltip/Tooltip';
import { BlockedUserProvider } from '@/context/BlockedUserProvider';
import { ConsentProvider } from '@/context/ConsentProvider';
import { MaintenanceProvider } from '@/context/MaintenanceProvider';
import { ThemeProvider } from "@/context/ThemeProvider";
export default function Providers({
  children,
  session
}) {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        staleTime: 60 * 1000
      }
    }
  });
  return <QueryClientProvider client={queryClient} data-sentry-element="QueryClientProvider" data-sentry-component="Providers" data-sentry-source-file="providers.jsx">
      <SessionProvider session={session} refetchInterval={60 * 5} refetchOnWindowFocus={false} refetchWhenOffline={false} data-sentry-element="SessionProvider" data-sentry-source-file="providers.jsx">
        <ThemeProvider data-sentry-element="ThemeProvider" data-sentry-source-file="providers.jsx">
          <ConsentProvider data-sentry-element="ConsentProvider" data-sentry-source-file="providers.jsx">
            <MaintenanceProvider data-sentry-element="MaintenanceProvider" data-sentry-source-file="providers.jsx">
              <BlockedUserProvider data-sentry-element="BlockedUserProvider" data-sentry-source-file="providers.jsx">
                <SidebarProvider defaultOpen={true} data-sentry-element="SidebarProvider" data-sentry-source-file="providers.jsx">
                  <TooltipProvider delayDuration={100} data-sentry-element="TooltipProvider" data-sentry-source-file="providers.jsx">
                    {children}
                    <EmailVerify data-sentry-element="EmailVerify" data-sentry-source-file="providers.jsx" />
                    <Tour data-sentry-element="Tour" data-sentry-source-file="providers.jsx" />
                    <Tracking data-sentry-element="Tracking" data-sentry-source-file="providers.jsx" />
                    <WebVitals data-sentry-element="WebVitals" data-sentry-source-file="providers.jsx" />
                  </TooltipProvider>
                </SidebarProvider>
              </BlockedUserProvider>
            </MaintenanceProvider>
          </ConsentProvider>
        </ThemeProvider>
      </SessionProvider>
    </QueryClientProvider>;
}