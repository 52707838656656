'use client'

import { useReportWebVitals } from 'next/web-vitals'
import { DEV_FLAGS } from '@/data'
import { useDevStore } from '@/store'

export function WebVitals() {
  const { isDev, hasFlag } = useDevStore()

  useReportWebVitals((metric) => {
    if (isDev && hasFlag(DEV_FLAGS.SHOW_WEB_VITALS)) {
      console.log(metric)
    }
  })

  return null
}

export default WebVitals
