'use client';

import React from 'react';
import Badge from '@/components/ui/Badge';
import { LogoFull } from '@/components/ui/Logo/Logo';
import SectionTitle from '@/components/ui/SectionTitle/SectionTitle';
import Wrapper from '@/components/ui/Wrapper';
import { ROUTES_DATA } from '@/data';
import { useTranslations } from '@/hooks';
export default function Maintenance() {
  const t = useTranslations();
  const PianoPlayground = React.lazy(() => import('@/components/Piano/PianoPlayground'));
  return <Wrapper className="maintenance" data-sentry-element="Wrapper" data-sentry-component="Maintenance" data-sentry-source-file="Maintenance.jsx">
      <div className="maintenance-logo">
        <LogoFull data-sentry-element="LogoFull" data-sentry-source-file="Maintenance.jsx" />
      </div>

      <SectionTitle className="pt-0 mt-0" title={t('maintenance.title')} subtitle={t('maintenance.subtitle')} badge={<Badge variant="primary-light" icon={ROUTES_DATA.MAINTENANCE.icon} label={t('maintenance.title')} />} data-sentry-element="SectionTitle" data-sentry-source-file="Maintenance.jsx">
        <p className="max-w-4xl mt-4 md:text-xl">
          {t('maintenance.body')}
        </p>
      </SectionTitle>

      <React.Suspense fallback={null} data-sentry-element="unknown" data-sentry-source-file="Maintenance.jsx">
        <PianoPlayground data-sentry-element="PianoPlayground" data-sentry-source-file="Maintenance.jsx" />
      </React.Suspense>

      <div className="maintenance-footer">
        <p className="mt-4 text-lg opacity-50">
          {t('maintenance.footer')}
        </p>
      </div>
    </Wrapper>;
}