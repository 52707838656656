import React, { useState } from 'react';
import Button from '@/components/ui/Button';
import { Dialog, DialogContent, DialogFooter, DialogHeader, DialogTitle } from '@/components/ui/Dialog';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
export const DialogReadMore = ({
  text,
  title,
  maxChars = 150,
  maxLines,
  className,
  trigger,
  preview,
  contentClasses,
  previewClasses
}) => {
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const t = useTranslations();
  if (!text) return null;
  let truncatedText = text;
  let textOverflow = false;
  if (text.length > maxChars) {
    truncatedText = `${text.substring(0, maxChars)}...`;
    textOverflow = true;
  }
  if (maxLines && !preview) {
    const lines = text.split('\n');
    if (lines.length > maxLines) {
      truncatedText = lines.slice(0, maxLines).join('\n') + '...';
      textOverflow = true;
    }
  }
  return <div className={cn('flex flex-col items-start gap-2', className)} data-sentry-component="DialogReadMore" data-sentry-source-file="DialogReadMore.jsx">
      <div className={cn(previewClasses)}>
        {preview || truncatedText}
      </div>

      {textOverflow && <>
          {trigger && React.cloneElement(trigger, {
        onClick: () => setIsDialogOpen(true)
      })}

          <Dialog open={isDialogOpen} onOpenChange={open => !open && setIsDialogOpen(false)}>
            <DialogContent>
              {title && <DialogHeader>
                  <DialogTitle>
                    {title}
                  </DialogTitle>
                </DialogHeader>}

              <div className={cn(contentClasses)}>
                {text}
              </div>

              <DialogFooter className="mt-4">
                <Button variant="light" className="w-full" label={t('common.actions.close')} onClick={() => setIsDialogOpen(false)} />
              </DialogFooter>
            </DialogContent>
          </Dialog>
        </>}
    </div>;
};
export default DialogReadMore;