'use client'
import { useEffect, useRef } from 'react'
import { isMobile } from 'react-device-detect'
import Shepherd from 'shepherd.js'
import { ROUTES, TOURS, TOUR_OPTIONS, VERIFY_EMAIL_TOUR_STEPS } from '@/data'
import { useActiveRoute, useSession, useTranslations } from '@/hooks'
import { usePathname } from '@/i18n/routing'
import useTourStore from '@/store/useTourStore'

export const Tour = () => {
  const tourRef = useRef(null)
  const t = useTranslations()
  const pathname = usePathname()

  const { data: session } = useSession()
  const { user } = session || {}

  const { isActive, getCurrentPath } = useActiveRoute()
  const { hasSeen, addSeenTour } = useTourStore()

  const resetTourSteps = () => {
    if (tourRef.current) {
      tourRef.current.complete()
    }

    tourRef.current = new Shepherd.Tour(TOUR_OPTIONS)
  }

  const canShowTour = () => {
    if (tourRef.current) {
      return !hasSeen(tourRef.current.tourId)
    }
    return true
  }

  const setTour = (set) => {
    const steps = tourSteps(set.steps)
    tourRef.current.addSteps(steps)
    tourRef.current.tourId = set.tourId
    tourRef.current.startDelay = set.delay
  }

  const setTourSteps = () => {
    const currentPath = getCurrentPath()
    const tourSet = TOURS.find((set) => set.route === currentPath)
    if (tourSet) {
      setTour(tourSet)
    }
  }

  const tourProgress = () => {
    const currentStep = tourRef.current.currentStep?.id
    const currentStepElement = tourRef.current.currentStep?.el
    const totalSteps = tourRef.current.steps.length
    const currentStepIndex =
      tourRef.current.steps.findIndex((step) => step.id === currentStep) + 1
    const percent = (currentStepIndex / totalSteps) * 100

    const header = currentStepElement.querySelector('.shepherd-header')
    const progressSteps = document.createElement('span')
    progressSteps.classList.add('tour-steps')

    const progressBar = document.createElement('div')
    const progress = document.createElement('div')
    progressBar.appendChild(progress)
    progressBar.classList.add('tour-progress')
    progress.style.width = `${percent}%`

    progressSteps.innerText = `${tourRef.current.steps.indexOf(tourRef.current.currentStep) + 1}/${tourRef.current.steps.length}`

    header.insertBefore(
      progressSteps,
      currentStepElement.querySelector('.shepherd-title')
    )
    header.insertBefore(
      progressBar,
      currentStepElement.querySelector('.shepherd-cancel-icon')
    )
  }

  const tourSteps = (steps) => {
    let tourButtons = []

    const nextButton = {
      classes: 'button button-primary ml-auto',
      text: t('common.navigation.next'),
      action: () => {
        tourRef.current.next()
      },
    }

    const backButton = {
      classes: 'button button-outline-primary',
      text: t('common.navigation.back'),
      action: () => {
        tourRef.current.back()
      },
    }

    const endButton = {
      classes: 'button button-primary ml-auto',
      text: t('common.navigation.end'),
      action: () => {
        tourRef.current.complete()
      },
    }

    return steps.map((step, index) => {
      if (steps.length === 1) {
        tourButtons = [endButton]
      } else if (index === 0) {
        tourButtons = [nextButton]
      } else if (index === steps.length - 1) {
        tourButtons = [backButton, endButton]
      } else {
        tourButtons = [backButton, nextButton]
      }

      const title = t.raw(step.title, { shouldParse: true })
      const text = t.raw(step.text, { shouldParse: true })

      return {
        ...step,
        title,
        text,
        buttons: tourButtons,
        attachTo:
          isMobile && step?.attachToMobile
            ? step.attachToMobile
            : step.attachTo,
        when: {
          show() {
            document.body.classList.add('tour-active')
            tourProgress()
          },
          hide: function () {
            document.body.classList.remove('tour-active')
          },
        }
      }
    })
  }

  const startTour = () => {
    setTimeout(() => {
      if (canShowTour()) {
        tourRef.current.start()
        addSeenTour(tourRef.current.tourId)
      }
    }, tourRef.current.startDelay)
  }

  useEffect(() => {
    resetTourSteps()

    if (
      tourRef.current &&
      user?.verified === false &&
      isActive(ROUTES.PROFILE)
    ) {
      setTour(VERIFY_EMAIL_TOUR_STEPS)
      startTour()
    } else if (tourRef.current && user?.verified) {
      setTourSteps()
      startTour()
    }

    return () => {
      if (tourRef.current) {
        tourRef.current.complete()
      }
    }
  }, [user, pathname])

  return null
}

export default Tour