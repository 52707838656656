import Badge from '@/components/ui/Badge';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/Tooltip/Tooltip';
import { useTranslations } from '@/hooks';
import { cn } from '@/utils';
export const BadgesList = ({
  tags,
  icon,
  variant = 'light',
  size,
  hiddenVariant = 'primary-light',
  moreLabel = 'common.labels.other_n_genres',
  maxVisible = 10,
  maxTotal = 20,
  className
}) => {
  const t = useTranslations();
  const classes = cn({
    'flex flex-wrap gap-1': true,
    [className]: className
  });
  if (!tags || tags.length === 0) {
    return null;
  }
  const visibleTags = tags.slice(0, maxVisible);
  const remainingTags = tags.slice(maxVisible, maxTotal);
  const hiddenTags = tags.length > maxTotal ? tags.length - maxTotal : 0;
  const hasMoreTags = remainingTags.length > 0 || hiddenTags > 0;
  return <div className={classes} data-sentry-component="BadgesList" data-sentry-source-file="BadgesList.jsx">
      {visibleTags.map(tag => <Badge key={tag} size={size} icon={icon} label={tag} variant={variant} />)}

      {hasMoreTags && <Tooltip>
          <TooltipTrigger>
            <Badge size={size} label={`+${remainingTags.length + hiddenTags}`} variant={variant} />
          </TooltipTrigger>

          <TooltipContent className="max-w-[320px] py-3" side="bottom">
            <div className="flex flex-wrap gap-1">
              {remainingTags.map(tag => <Badge key={tag} size={size} label={tag} variant={hiddenVariant} />)}

              {hiddenTags > 0 && <Badge size={size} label={t(moreLabel, {
            n: hiddenTags
          })} variant={hiddenVariant} />}
            </div>
          </TooltipContent>
        </Tooltip>}
    </div>;
};
export default BadgesList;