'use client';

import { truncate } from 'lodash-es';
import { useRouter } from 'next/navigation';
import Button from '@/components/ui/Button';
import Icon from '@/components/ui/Icon';
import { Tooltip, TooltipContent, TooltipTrigger } from '@/components/ui/Tooltip/Tooltip';
import { Link } from '@/i18n/routing';
import { cn } from '@/utils';
function Badge({
  children,
  className,
  image,
  clearable,
  emoji,
  icon,
  iconRight,
  iconType,
  label,
  labelClasses,
  labelHref,
  onClick,
  onClear,
  medium,
  size,
  href,
  tooltip,
  value,
  values,
  truncateLength,
  wrapValue,
  variant = 'primary',
  vertical,
  ...props
}) {
  const router = useRouter();
  const handleBadgeClick = () => {
    if (onClick) {
      onClick();
    }
  };
  const handleLabelClick = () => {
    if (labelHref) {
      router.push(labelHref);
    }
  };
  const BadgeWrapper = ({
    children,
    ...props
  }) => {
    if (href) {
      return <Link href={href} {...props} onClick={handleBadgeClick}>
          {children}
        </Link>;
    } else if (tooltip) {
      return <Tooltip>
          <TooltipTrigger asChild>
            <div>{children}</div>
          </TooltipTrigger>
          <TooltipContent className="max-w-[280px]">
            <span>{tooltip}</span>
          </TooltipContent>
        </Tooltip>;
    }
    return children;
  };
  const labelCn = cn({
    'badge-label': true,
    'cursor-pointer': labelHref,
    [labelClasses]: labelClasses
  });
  const classes = cn({
    badge: true,
    [`badge-${variant}`]: variant,
    [size]: size,
    'badge-icon': !label && !children,
    'badge-vertical': vertical,
    'cursor-pointer': onClick,
    [className]: className
  });
  return <BadgeWrapper {...props} data-sentry-element="BadgeWrapper" data-sentry-component="Badge" data-sentry-source-file="Badge.jsx">
      <div className={classes} onClick={handleBadgeClick}>
        {(emoji || icon || label) && <div className={labelCn} onClick={handleLabelClick}>
            {emoji && emoji}

            {image && <img src={image} alt={label} />}

            {icon && <Icon name={icon} />}

            {label && !truncateLength && <span>{label}</span>}

            {label && truncateLength && <span title={label}>
                {truncate(label, {
            length: truncateLength
          })}
              </span>}
          </div>}

        {values && <div className="flex badge-values">
            {values.map(({
          value,
          icon
        }, index) => <div key={index} className="flex gap-1 badge-value">
                {icon && <Icon name={icon} />}
                {value}
              </div>)}
          </div>}

        {value && <span className={cn({
        'badge-value': emoji || icon || label,
        'text-wrap': wrapValue
      })}>
            {value}
          </span>}

        {iconRight && <Icon className="ml-2" name={iconRight} />}

        {clearable && <Button variant="ghost" size="sm" icon="X" className="badge-clear" onClick={onClear} />}

        {children}
      </div>
    </BadgeWrapper>;
}
export default Badge;